










































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CriteriaBaseAvecFiltresRapides, ItemFiltreRapide } from '@/shared/models';

@Component({
    name: 'FiltreRapide',
})
export default class FiltreRapide<T extends CriteriaBaseAvecFiltresRapides> extends Vue {

    // Sert à désactiver les filtres si jamais un chargement est en cours.
    @Prop({ default: false })
    public loading!: boolean;

    // Libellés et mappings des filtres rapides.
    @Prop({ default: () => new Array<ItemFiltreRapide>() })
    public items!: ItemFiltreRapide[];

    // Les critères de recherche.
    @Prop()
    public criteria!: T;

    /**
     * Emit d'un évènement pour indiquer un changement de filtre.
     * */
    public emitClickFiltreRapide() {
        this.$emit('click-filtre-rapide');
    }

    // Affiche ou non le contenu de la card.
    public show = true;

    // Liste des items sélectionnés. 'Tous' d'index 0 sélectionné par défaut.
    public filtresRapidesSelectionnes: number[] = [0];

    /**
     * Inverse la valeur d'un filtre. Le passe à vrai si il n'est pas sélectionné, à faux si il était sélectionné.
     * @param item L'item cliqué.
     */
    public reverseFiltre(item: ItemFiltreRapide) {

        if (!this.criteria) {
            return;
        }

        // Désélectionne 'Tous'.
        if (this.filtresRapidesSelectionnes.includes(0)) {
            this.filtresRapidesSelectionnes.splice(this.filtresRapidesSelectionnes.indexOf(0), 1);
        }

        // Si la propriété n'existe pas, on la rajoute à vrai.
        if (!this.criteria.filtresRapides.hasOwnProperty(item.mapping)) {
            this.criteria.filtresRapides[item.mapping] = true;
        } else {
            // Si la propriété existe, on inverse la valeur du booléen.
            this.criteria.filtresRapides[item.mapping] = !this.criteria.filtresRapides[item.mapping];
        }

        // Emit d'un évènement pour indiquer un changement de filtre.
        this.emitClickFiltreRapide();
    }

    /**
     * Watcher pour resélectionner 'Tous' si aucun item n'est sélectionné.
     * */
    @Watch('filtresRapidesSelectionnes')
    public reselectionneTous() {
        if (this.filtresRapidesSelectionnes.length === 0) {
            this.filtresRapidesSelectionnes = [0];
        }
    }

    /**
     * Désactive tous les filtres sélectionnés.
     * */
    public desactiveTousLesFiltres() {
        // Ne laisse sélectionné que l'item qui a l'index 0 qui correspond à Tous.
        this.filtresRapidesSelectionnes.splice(0, this.filtresRapidesSelectionnes.length);
        this.filtresRapidesSelectionnes = [0];

        // Passe les filtres à faux.
        this.items.forEach(item => this.criteria.filtresRapides[item.mapping] = false);

        // Emit d'un événement pour indiquer un changement de filtre.
        this.emitClickFiltreRapide();
    }
}
