var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"cliquable",attrs:{"headers":_vm.colonnesExterne,"items":_vm.items,"item-key":"id","options":_vm.pagination,"server-items-length":_vm.totalItems,"items-per-page-text":_vm.pagination.itemsPerPageText,"footer-props":{
                pageText: '{0} - {1} sur {2}',
                itemsPerPageOptions: _vm.pagination.itemsPerPageOptions,
              },"disable-sort":true,"no-data-text":_vm.loading ? 'Chargement des données en cours...' : 'Aucune donnée à afficher',"loading":_vm.loading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('router-link',{attrs:{"tag":"tr","to":{ name: _vm.redirectionRouter(props.item.simulationOuDossier) , params: { id: props.item.id }}}},[_c('td',{staticClass:"no-wrap-cell"},[_c('span',[_vm._v(_vm._s(props.item.numeroDossier))])]),_c('td',{staticClass:"no-wrap-cell"},[_c('span',[_vm._v(_vm._s(props.item.nomDossier))])]),_c('td',[(!!props.item.notes && JSON.parse(props.item.notes).length > 0)?_c('v-tooltip',{attrs:{"top":"","disabled":props.item.operations.length <= 0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("[...]")])]}}],null,true)},[_c('span',[(JSON.parse(props.item.notes).length === 1)?_c('span',[_vm._v(_vm._s(("" + (JSON.parse(props.item.notes)[0].message))))]):(!!props.item.notes)?_c('ul',_vm._l((JSON.parse(props.item.notes)),function(item,index){return _c('li',{key:index+'-'+item.createur+'-'+item.message},[_vm._v(" "+_vm._s(((item.createur) + " : " + (item.message)))+" ")])}),0):_vm._e()])]):_vm._e()],1),_c('td',{staticClass:"no-wrap-cell"},[(props.item.operations.length === 1)?_c('span',[_vm._v(_vm._s(props.item.operations.map(function (x) { return x; })[0]))]):_c('ul',_vm._l((props.item.operations),function(item,index){return _c('li',{key:item.code+'-'+index},[_vm._v(_vm._s(item))])}),0)]),_c('td',[_vm._v(_vm._s(props.item.beneficiaire))]),_c('td',[_vm._v(_vm._s(props.item.installateur))]),_c('td',[_vm._v(_vm._s(props.item.siteTravaux))]),_c('td',[_c('v-tooltip',{attrs:{"top":"","disabled":props.item.operations.length <= 0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("numberFormat")(_vm._f("toMWhc")(_vm.getSommeVolumesCee(props.item)),undefined, 5)))])]}}],null,true)},[_c('span',[_vm._v(" Classique : "+_vm._s(_vm._f("numberFormat")(_vm._f("toMWhc")(props.item.volumeCeeClassique),undefined, 5))+" MWhc "),_c('br'),_vm._v(" Précarité : "+_vm._s(_vm._f("numberFormat")(_vm._f("toMWhc")(props.item.volumeCeePrecarite),undefined, 5))+" MWhc ")])])],1),_c('td',[_c('v-tooltip',{attrs:{"top":"","disabled":props.item.operations.length <= 0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("numberFormat")(_vm.getMontantTotal(props.item),undefined, props.item.precisionAffichage)))])]}}],null,true)},[_c('span',[_vm._v(" Classique : "+_vm._s(_vm._f("numberFormat")(props.item.montantTotalClassique,undefined, props.item.precisionAffichage))+" € "),_c('br'),_vm._v(" Précarité : "+_vm._s(_vm._f("numberFormat")(props.item.montantTotalPrecarite,undefined, props.item.precisionAffichage))+" € ")])])],1),(_vm.isApporteurAffaire)?_c('td',[_vm._v(_vm._s(_vm._f("numberFormat")(props.item.valorisationApporteurAffaires,undefined, props.item.precisionAffichage)))]):_vm._e(),_c('td',[_vm._v(_vm._s(props.item.dateAchevementTravaux))]),_c('td',{staticClass:"no-wrap-cell"},[_c('span',[_vm._v(_vm._s(props.item.statut))])]),_c('td',[(_vm.displayGestionnaireByStatut(props.item))?_c('span',[_vm._v(_vm._s(_vm.displayGestionnaireByStatut(props.item))),_c('br')]):_vm._e(),_vm._v(" "+_vm._s(_vm.displaySentenceByStatut(props.item))+" "+_vm._s(props.item.dateByStatut)+" ")]),_c('td',[_c('div',{staticClass:"grid-actions"},[(_vm.showActionDuplication)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"success"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('dupliquer-simulation-dossier', props.item)}}},on),[_c('v-icon',[_vm._v("file_copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Dupliquer")])]):_vm._e(),(_vm.showActionInverserActivationSeuilMinimumOperation)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('inverser-activation-seuil-minimum-operation', props.item)}}},on),[_c('v-icon',[_vm._v(_vm._s(props.item.isSeuilMinimumOperationDesactive ? 'lock_open' : 'lock'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.isSeuilMinimumOperationDesactive ? 'Activer' : 'Désactiver')+" seuil minimum d'opération")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"red","disabled":!_vm.canDeleteSimulationDossier(props.item)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('supprimer-simulation-dossier', props.item)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer")])])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }