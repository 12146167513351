import { Mixins, Prop, Component } from 'vue-property-decorator';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { DataTableHeader, PagingAndSorting } from '@/shared/models';
import { SimulationDossierCriteria } from './models/SimulationDossierCriteria.model';
import { Profils } from '@/shared/enums';
import { UserProfile } from '@/store/modules/auth/types';
import { SimulationOuDossier } from '@/shared/enums/SimulationOuDossier.enum';
import { GroupeStatutsSimulationDossier } from './models/GroupeStatutsSimulationDossier';
import { StatutDossierAsString } from '@/shared/enums/StatutDossier.enum';
import template from './SimulationTabUserExterne.vue';
import { TableauDeBordUserExterne } from '../../models/TableauDeBordUser.model';

@Component({
    ...template,
    name: 'SimulationTabUserExterne',
})
export default class SimulationsTabUserExterne extends Mixins(GrilleMixin) {
    // Elements de la grille.   
    @Prop({ default: () => new Array<TableauDeBordUserExterne>() })
    public items!: TableauDeBordUserExterne[];

    @Prop({ default: 0 })
    public totalItems!: number;

    // Détermine si il faut afficher le bouton de duplication.
    @Prop({ default: false })
    public showActionDuplication!: boolean;

    // Détermine si il faut afficher le bouton de désactivation du seuil minimum d'opération.
    @Prop({ default: false })
    public showActionInverserActivationSeuilMinimumOperation!: boolean;

    // Chargement des éléments de la grille en cours ou non.
    @Prop({ default: false })
    public loading: boolean;

    // Critères de recherche avec la pagination.
    @Prop({ default: new SimulationDossierCriteria() })
    public simulationDossierCriterias: SimulationDossierCriteria;

    // Méthode permettant de charger les simulations/dossier.
    @Prop({ default: new SimulationDossierCriteria() })
    public chargerSimulationDossier: (simulationDossierCriterias: SimulationDossierCriteria) => void;

    @Prop({ default: () => new Array<GroupeStatutsSimulationDossier>() })
    public statuts: Array<GroupeStatutsSimulationDossier>;

    /**
     * Le profil de l'utilisateur.
     */
    @Prop()
    public userProfile: UserProfile;


    /**
     * Indique si l'utilisateur est un apporteur d'affaires.
     */
    get isApporteurAffaire(): boolean {
        return this.userProfile &&
            this.userProfile.profilCode === Profils.ApporteurAffaires;
    }
    /**
     * Indique si l'utilisateur peut supprimer la simulation/le dossier.
     */
    public canDeleteSimulationDossier(simulationDossier: TableauDeBordUserExterne): boolean {
        // #80007 : Utilisateur externes -> Une fois le dossier envoyé : griser la fonction supprimer
        const statutsBloquesPourExterne = [
            StatutDossierAsString.ENVOYE_ENGIE,
            StatutDossierAsString.EN_TRAITEMENT_ENGIE,
            StatutDossierAsString.FINALISE_ENGIE,
            StatutDossierAsString.DEPOSE_PNCEE,
            StatutDossierAsString.TRAITE_PNCEE,
            StatutDossierAsString.NON_DEPOSE_PNCEE,
            StatutDossierAsString.PAYE_ENGIE,
        ];

        return !statutsBloquesPourExterne.find(x => x === simulationDossier.statut);
    }

    public redirectionRouter(simulationOuDossier: SimulationOuDossier): string {
        return simulationOuDossier === SimulationOuDossier.Simulation ?
            'editer-simulation' : 'editer-dossier';
    }

    // Colonnes du tableau
    get colonnesExterne(): DataTableHeader[] {

        const colonnes: DataTableHeader[] = [
            { text: 'N° dossier', id: 'numeroDossier' },
            { text: 'Nom du\ndossier', id: 'nomDuDossier' },
            { text: 'Note', id: 'note' },
            { text: 'Opérations', id: 'operations' },
            { text: 'Bénéficiaire', id: 'beneficiare' },
            { text: 'Installateur', id: 'raisonSocialeInstallateur' },
            { text: 'Site des travaux', id: 'nomDuSiteTravaux' },
            { text: 'Volume CEE\n(MWhc)', id: 'VolumeCumas' },
            { text: 'Montant total \nCEE (€)', id: 'Valorisation' },
            { text: 'Rémunération \napporteur d´affaires (€)', id: 'remunerationApporteurAffaire' },
            { text: 'Date d´achèvement \ndes travaux', id: 'dateAchevement' },
            { text: 'Statut', id: 'statut' },
            { text: 'Informations\ncomplémentaires', id: 'infoComplementaire' },
            { text: '', id: '' },
        ];

        // Colonnes à ne pas afficher pour un compte externe
        const colonnesInvisibleExternes = [];
        // Si le compte c'est un externe mais apporteur d'affaires ne pas cacher la colonne rémunération apporteur d'affaires.
        if (!this.isApporteurAffaire) {
            colonnesInvisibleExternes.push('remunerationApporteurAffaire');
        }
        colonnesInvisibleExternes.forEach((element) => {
            colonnes.splice(colonnes.findIndex((c) => c.id === element), 1);
        });

        return colonnes;
    }

    // Override du getData de GrilleMixin.
    public getData() {
        this.simulationDossierCriterias.pagingAndSorting = PagingAndSorting.buildFromPaginationObject(
            this.pagination
        );
        this.chargerSimulationDossier(this.simulationDossierCriterias);
    }

    /**
    * Renvoie la somme total du volume CEE des operations d'une simulation/dossier
    * @param simulationDossier
    * @returns Somme totale volume CeePrecarite + CeeClassique
    */
    public getSommeVolumesCee(simulationDossier: TableauDeBordUserExterne): number {
        return simulationDossier.volumeCeeClassique + simulationDossier.volumeCeePrecarite;
    }

    /**
    * Renvoie le montant total d'un dossier ou simulation (Classique + Precarité)
    * @param Dossier
    */
    public getMontantTotal(simulationUserInterne: TableauDeBordUserExterne): number {
        return simulationUserInterne.montantTotalClassique + simulationUserInterne.montantTotalPrecarite;
    }

    /**
     * Gère l'affichagre du gestionnaire par statut
     * @param simulationDossier
     */
    public displayGestionnaireByStatut(simulationDossier: TableauDeBordUserExterne): string
    {
        // Statut où l'on affiche le gestionnaire
        const statutsGestionnaire: string[] = [
            StatutDossierAsString.EN_TRAITEMENT_ENGIE.toString(),
            StatutDossierAsString.FINALISE_ENGIE.toString(),
            StatutDossierAsString.PAYE_ENGIE.toString()
        ];
        if (!!simulationDossier.dateByStatut && statutsGestionnaire.includes(simulationDossier.statut)) {
                return simulationDossier.gestionnaireEngie ? simulationDossier.gestionnaireEngie.trim() : null;
        }

        return null;
    }

    /**
     * Gère l'affichage d'une phrase indicative selon le statut pour les dates de la colonne informations complementaires
     * @param simulationDossier
     */
    public displaySentenceByStatut(simulationDossier: TableauDeBordUserExterne): string {

        let phraseAffichage = "";
        if (!!simulationDossier.dateByStatut) {
            switch (simulationDossier.statut) {
                case StatutDossierAsString.EN_CREATION:
                    phraseAffichage = "Date limite d'envoi : ";
                    break;
                case StatutDossierAsString.ENVOYE_ENGIE:
                    phraseAffichage = "Envoyé le ";
                    break;
                case StatutDossierAsString.EN_TRAITEMENT_ENGIE:
                    phraseAffichage = `Pris en charge le : `;
                    break;
                case StatutDossierAsString.FINALISE_ENGIE:
                    phraseAffichage = `Finalisé le : `;
                    break;
                case StatutDossierAsString.PAYE_ENGIE:
                    phraseAffichage = `Mis en paiement le : `;
                    break;
            }
        }
        return phraseAffichage;
    }
}
