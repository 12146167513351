





























import {Vue, Prop, Component } from 'vue-property-decorator'
import { ValeurReferentielle } from '@/shared/models';
import { SimulationOuDossier } from '@/shared/enums/SimulationOuDossier.enum';

@Component({
  name: 'StatutSimulationDossier',
})
export default class StatutSimulationDossier extends Vue{

    @Prop({default: []})
    public statuts: Array<ValeurReferentielle & {simDoss: SimulationOuDossier}>;

    public selectedStatut: ValeurReferentielle & {simDoss: SimulationOuDossier} = null;

    public parcoursUtilisateurId: number = 0;

    @Prop({default: []})
    public parcoursUtilisateur: ValeurReferentielle[];

    public onChangeStatut(value: ValeurReferentielle & {simDoss: SimulationOuDossier}): void {
      this.parcoursUtilisateurId = value.simDoss;
      this.$emit('changeStatut', value);
    }

    public initialiserOnClear(): void {
        this.parcoursUtilisateurId = 0;
        this.selectedStatut = null;
        this.$emit('clear');
        this.$emit('changeStatut', null);
    }
}
