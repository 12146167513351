import { Vue, Component } from 'vue-property-decorator';
import SimulationTabUserExterne from './SimulationTabUserExterne.vue';
import FilterCard from '@/components/FilterCard.vue';
import FiltreRapide from '@/components/FiltreRapide.vue';
import Confirm from '@/components/Confirm.vue';
import DialogLoader from '@/components/DialogLoader.vue';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import SimulationTabUserInterne from './SimulationTabUserInterne.vue';
import { Simulation } from '@/models/Simulation.model';
import { SimulationDossierCriteria } from './models/SimulationDossierCriteria.model';
import { Getter, Mutation } from 'vuex-class';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { UserProfile } from '@/store/modules/auth/types';
import { ApiService } from '@/services/base/ApiService';
import { PagingAndSorting, Pagination, FileContentResult, Result, ValeurReferentielle } from '@/shared/models';
import { Profils } from '@/shared/enums';
import { Profil, SimulationDossier, Utilisateur, AccordCadre, Societe, TableauDeBordUserBase } from '@/models';
import ApiHelper from '@/services/ApiHelper';
import { AxiosResponse } from 'axios';
import { SimulationOuDossier } from '@/shared/enums/SimulationOuDossier.enum';
import StatutSimulationDossier from './StatutSimulationDossier.vue';
import { GroupeStatutsSimulationDossier } from './models/GroupeStatutsSimulationDossier';
import { DateHelper } from '@/shared/helpers';
import { AccordCadreCriteria } from '@/views/Administration/AccordsCadres/AccordCadreCriteria.model';
import { TableauDeBordReferentiels } from '@/models/TableauDeBordReferentiels.model';
import { TableauDeBordStoreMethods } from '../../store/modules/TableauDeBord/TableauDeBordStore';


@Component({
    name: 'TableauDeBord',
    components: {
        FilterCard,
        FiltreRapide,
        Confirm,
        DialogLoader,
        CeeAutocomplete,
        StatutSimulationDossier,
        SimulationTabUserInterne,
        SimulationTabUserExterne,
    },
})
export default class TableauDeBord extends Vue {
    public simulationDossierApiService = new ApiService<TableauDeBordUserBase>('/tableauDeBord/RecupererSimulationDossierTableauDeBord');

    // Définition de refs.
    public $refs!: Vue['$refs'] & {
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string, width?: number, zIndex?: number }) => Promise<boolean>),
        },
        dialogLoader: {
            start: ((message: string | null,
                options: { color: string, width: number, zIndex: number },
                callback: (() => Promise<void>),
                snackbar: boolean | { enabled: boolean },
            ) => Promise<boolean>),
        },
    };

    // Promesse pour récupérer le profil du user.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<UserProfile>;

    @Getter(AuthStoreMethods.USER)
    public user: Promise<Utilisateur>;

    public userProfile: UserProfile | null = null;

    // Met l'énumération à disposition de la vue.
    public SimulationDossier = SimulationDossier;

    // La liste des simulations à afficher en fonction des critères de recherche.
    public simulationsDossiers: TableauDeBordUserBase[] = new Array<TableauDeBordUserBase>();

    @Getter(TableauDeBordStoreMethods.GET_CONSULTED_DOSSIERS)
    public simulationsDossiersConsulted: TableauDeBordUserBase[];

    @Getter(TableauDeBordStoreMethods.GET_LAST_FILTERS)
    public lastFilters: SimulationDossierCriteria;

    @Mutation(TableauDeBordStoreMethods.ADD_CONSULTED_DOSSIER)
    public addConsultedDossier: (simulationDossier: TableauDeBordUserBase) => void;

    @Mutation(TableauDeBordStoreMethods.ADD_LAST_FILTERS)
    public addLastFilters: (lastCriterias: SimulationDossierCriteria) => void;

    public simulationsCount: number = 0;

    // Si la grille est en cours de chargement.
    public loading: boolean = false;

    public loadingData: boolean = false;

    // Indique si il faut afficher les filtres avancés.
    public afficherFiltresAvances: boolean = false;

    // Critères de recherches avancés.
    public criteresAvances: SimulationDossierCriteria = new SimulationDossierCriteria();

    // Indique si l'utilisateur est un interne.
    public isUserInterne: boolean = false;

    // Indique si l'utilisateur est un admin.
    public isUserAdmin: boolean = false;

    // Les types de profil.
    public typesDeComptes: Profil[] = [];

    public accordCadreCriteria: AccordCadreCriteria = new AccordCadreCriteria();

    // Indique si l'utilisateur est un apporteur d'affaires mais qu'il n'a pas de contrat en cours.
    public apporteurAffairesSansContratEnCours: boolean = false;

    // Les statuts dossier sont chargés directement.
    public statutsDossier = new Array<GroupeStatutsSimulationDossier>();
    // Dossier ou Simulation.    
    public parcoursUtilisateur: ValeurReferentielle[] = [];
    // Statuts
    public statuts: Array<GroupeStatutsSimulationDossier> = [];
    // Permettant la réinitialisation des statuts du démarrage en cas de recherche statut et dossier.
    public statusBase: Array<GroupeStatutsSimulationDossier> = [];
    // Statut sélectionné.
    public selectedStatut: GroupeStatutsSimulationDossier = null;
    // Accord cadre sélectionné
    public selectedAccordCadreId: number = null;
    // Numéro du tab
    public tab: string = 'dossiers';
    // Numéro du tab correspondant au filtre du tri.
    public tabTriDateDerniereModif: string = 'defaut';
    // Accords CAdres
    public accordsCadres: AccordCadre[] = [];
    // Gestionnaires Engie
    public gestionnairesEngie: Utilisateur[] = [];
    // Accords cadres pour autocomplétion
    public accordsCadresAutocomplete: AccordCadre[] = [];
    // Cette proprité sert à indiquer aux enfants si le parent a fini de monter ses propriétés. Sinon l'enfant SimulationTabUserExterne
    // va commencer à se monter et declencher un double appel aux données du tableau de bord comme isUserInterne est false par default.
    private parentCreated = false;

    private cleared = false;

    public hasAccesCreationSimulationDossier: boolean = true;

    public created() {
        this.getUserProfile.then((profil: UserProfile) => {
            this.userProfile = profil;
            this.isUserInterne = this.userProfile.isInterne;
            this.isUserAdmin = this.userProfile.isAdmin;
            this.hasAccesCreationSimulationDossier = profil.hasAccesCreationSimulationDossier;
            this.criteresAvances = this.lastFilters;           
            if (this.userProfile.profilCode === Profils.GestionnaireBO) {
                this.criteresAvances.parcoursUtilisateurId = SimulationOuDossier.Dossier;
            }
            if (this.criteresAvances.sortByDateModification) {
                this.tabTriDateDerniereModif = "dateDerniereModification";
            }
            this.criteresAvances.pagingAndSorting = PagingAndSorting.buildFromPaginationObject(new Pagination());
            this.rechercher();


            this.getTableaudeBordReferentiels().then((tdbReferentiels) => {
                tdbReferentiels.statutsSimulation.forEach((statut) => {
                    this.statuts.push(new GroupeStatutsSimulationDossier(statut));
                });
                this.statutsDossier = tdbReferentiels.groupeStatutDossiers;
                this.statutsDossier.forEach(x => x.type = SimulationOuDossier.Dossier);
                this.statuts = this.statuts.concat(this.statutsDossier);
                this.accordsCadres = tdbReferentiels.accordCadreUtilisateurCourantResponsable;
                this.typesDeComptes = tdbReferentiels.profils;
                this.gestionnairesEngie = tdbReferentiels.gestionnairesBo;
                this.parcoursUtilisateur = tdbReferentiels.parcoursUtilisateur;

                this.statusBase = this.statuts;
                // Vérification des contrats de l'utilisateur
                if (this.userProfile.profilCode === Profils.ApporteurAffaires && !this.userProfile.hasContratApporteurAffairesEnCours) {
                    this.apporteurAffairesSansContratEnCours = true;
                }
            }).finally(() => {
                this.parentCreated = true;
                if (this.criteresAvances.statutIds) {              
                    this.selectedStatut = this.statuts.filter(s => s.type === this.criteresAvances.parcoursUtilisateurId)
                        .find(x => x.ids[0] === this.criteresAvances.statutIds[0]);
                }                
            });
        });
    }

    public get estApporteurAffairesOuSDC(): boolean | void {
        if (!!this.userProfile && !!this.userProfile.profilCode) {
            return (this.userProfile.profilCode === Profils.SyndicSDC || this.userProfile.profilCode === Profils.ApporteurAffaires);
        }
    }
    public get simulateurOuDossier(): string | void {
        if (!!this.userProfile && !!this.userProfile.profilCode) {
            return this.estApporteurAffairesOuSDC ?
                'simulateur' : 'dossier';
        }
    }
    public get estChoixTabAutorise() {
        return this.accordsCadres.length > 0 && !this.isUserInterne;
    }
    public get estTabPrincipalDossier() {
        return this.tab === 'dossiers';
    }

    /**
     * Affiche un préfix au statut pour indiquer de quel type il s'agit.
     * @param simulationDossier type du statut.
     */
    public getPrefixStatut(simulationDossier: SimulationOuDossier) {

        // N'affiche le préfixe que s'il y a un doute.
        if (!this.criteresAvances.parcoursUtilisateurId) {
            return SimulationOuDossier[simulationDossier];
        }

        return '';
    }

    // Méthode de chargement des simulations.
    public chargerSimulationDossier(): Promise<void> {
        return new Promise((resolve) => {
            this.loading = true;           
            this.simulationDossierApiService.getWhere(this.lastFilters.queryString).then((value) => {
                this.simulationsDossiers = value.data.data;
                this.simulationsCount = value.data.totalCount;
                resolve();
            }).finally(() => {
                this.loading = false;
                if (this.cleared) {
                    this.giveFocus();
                }               
            });
        });
    }

    // Lance la recherche après avoir sélectionné un accord cadre.
    public rechercherAccordCadre(): void {
        this.criteresAvances.accordCadreIds = this.selectedAccordCadreId ? [this.selectedAccordCadreId] : [];
        this.rechercher();
    }

    // Lance la recherche des simulations après avoir réinitialisé la pagination de la grille.
    public rechercher(): Promise<void> {
        this.addLastFilters(this.criteresAvances);
        return this.chargerSimulationDossier();
    }

    // Effacer tous les filtres qui ont été saisis.
    public reinitialiserFiltres(): void {
        this.criteresAvances = new SimulationDossierCriteria();
        this.reinitialiserStatusEtParcours();
        this.addLastFilters(this.criteresAvances);
        this.rechercher();
    }

    /**
     * Recherche des raisons sociales correspondant à la saisie.
     * @param saisie Raison sociale saisie.
     */
    public rechercheRaisonSociale(saisie: string): Promise<string[]> {
        const apiRaisonSociale = new ApiService<string>(`/tableauDeBord/rechercheRaisonSociale/${saisie}`);
        return new Promise<string[]>((resolve, reject) => {
            apiRaisonSociale.getAll().then((reponse: AxiosResponse<Result<string[]>>) => {
                resolve(reponse.data.data);
            }).catch((error: any) => reject(error));
        });
    }


    public rechercherAccordCadreNew(saisie: string): Promise<AccordCadre[]> {
        const apiAccordCadre = new ApiService<AccordCadre>(`/accordCadre/rechercherAccordCadreNew/${saisie}`);
        return new Promise<AccordCadre[]>((resolve, reject) => {
            apiAccordCadre.getAll().then((reponse: AxiosResponse<Result<AccordCadre[]>>) => {
                resolve(reponse.data.data);
            }).catch((error: any) => reject(error));
        });
    }


    /**
     * Recherche des mails correspondant à la saisie.
     * @param saisie Mail saisi.
     */
    public rechercheMailUtilisateur(saisie: string): Promise<string[]> {
        const apiMail = new ApiService<string>(`/tableauDeBord/rechercheMailUtilisateur/${saisie}`);
        return new Promise<string[]>((resolve, reject) => {
            apiMail.getAll().then((reponse: AxiosResponse<Result<string[]>>) => {
                resolve(reponse.data.data);
            }).catch((error: any) => reject(error));
        });
    }

    // Exporter le résultat de la requête.
    public exporterRequete(): void {

        // Empêche l'utilisateur de lancer plusieurs exports simultanés par erreur.
        if (!this.loading) {
            this.loading = true;

            // Paramètres de l'export.
            let apiExport: ApiService<FileContentResult>;
            if (this.userProfile.isInterne) {
                apiExport = new ApiService<FileContentResult>(`/tableauDeBord/exporterSimulationsExcel`);
            }
            else {
                apiExport = new ApiService<FileContentResult>(`/tableauDeBord/exporterDossierUtilisateurExterne`);
            }
            this.criteresAvances.droitCoupDePouce = this.userProfile.hasAccesOperationsCoupDePouce;
            if (this.criteresAvances.isDossiersAccordCadres) {
                this.criteresAvances.accordCadreIds = this.selectedAccordCadreId ? [this.selectedAccordCadreId] : this.accordsCadres.map((ac) => ac.id);
            }
            apiExport.getWhereSingle(this.criteresAvances.queryString).then((reponse) => {
                ApiHelper.createAndDownloadBlobFile(reponse.data);
            }).finally(() => {
                this.loading = false;
            });
        }
    }

    // Reinitialiser les statuts.
    public reinitialiserStatusEtParcours() {
        this.criteresAvances.parcoursUtilisateurId = 0;
        this.criteresAvances.statutIds = [];
        this.selectedStatut = null;
        // On réinitialise les status du démarrage du composant.
        this.statuts = this.statusBase;
    }

    // Filtrer status et rechercher
    public filtrerStatusEtRechercher(parcoursUtilisateurId: number): void {
        if (parcoursUtilisateurId) {
            this.statuts = this.statusBase.filter(s => s.type === parcoursUtilisateurId);
        }
        // On ne peut faire une recherche sur un gestionnaire que si on est sur des dossiers.
        if (parcoursUtilisateurId !== SimulationOuDossier.Dossier) {
            this.criteresAvances.idGestionnaire = null;
        }
        this.rechercher();
    }

    public definirParamsEtRechercher(statutChoisi: GroupeStatutsSimulationDossier) {
        if (!!statutChoisi) {
            this.statuts = this.statusBase.filter(s => s.type === statutChoisi.type);
            this.criteresAvances.parcoursUtilisateurId = statutChoisi.type;
            this.criteresAvances.statutIds = statutChoisi.ids;
        }
        this.rechercher();
    }

    /**
     * Duplication de la simulation.
     * @param simulationId Identifiant de la simulation.
     */
    public async dupliquerSimulationDossier(simulation: Simulation) {
        const endpoint = this.criteresAvances.parcoursUtilisateurId === SimulationOuDossier.Simulation ?
            `/simulation/dupliquerSimulation/${simulation.id}` :
            `/dossier/dupliquerDossier/${simulation.id}`;
        const apiDuplication = new ApiService<Simulation>(endpoint);

        await this.faireUneActionSurSimulation(
            'Dupliquer un dossier ?',
            `Confirmez-vous la duplication du dossier<br/>${simulation.numeroDossier} ?`,
            `Duplication du dossier<br/>${simulation.numeroDossier}...`,
            { color: 'green', width: 500, zIndex: 200 },
            ((resolve: ((value?: void | PromiseLike<void>) => void)) => {
                apiDuplication.post(null).then((_) => {
                    // Rechargement des simulations quand la suppression est terminée.
                    this.chargerSimulationDossier();
                })
                    .finally(() => {
                        resolve();
                    });
            }));
    }

    /**
     * Récupère le texte d'un accord-cadre pour la sélection.
     * @param accord L'accord-cadre.
     */
    public getTexteAccordCadre(accord: AccordCadre): string {

        if (accord) {
            const prix = Intl
                .NumberFormat('fr-FR', { style: 'decimal', currency: 'EUR', maximumFractionDigits: 3 })
                .format(accord.valorisationClassique);

            return `${accord.nom} - ${prix} €/MWhc - Du ${DateHelper.format(accord.dateDebut)} au ${DateHelper.format(accord.dateFin)}`;
        }

        return undefined;
    }

    /**
     * Désactivation du seuil minmum d'opération pour la simulation.
     * @param simulation La simulation.
     */
    public async inverserActivationSeuilMinimumOperation(simulation: Simulation) {
        const apiDesactivationSeuilMinimum = new ApiService<Simulation>(`/simulationDossier/inverseActivationSeuilMinimumOperation/${simulation.id}`);
        const stringActiver = simulation.isSeuilMinimumOperationDesactive ? 'Activer' : 'Désactiver';
        const stringActivation = simulation.isSeuilMinimumOperationDesactive ? '\'Activation' : 'a Désactivation';

        await this.faireUneActionSurSimulation(
            `${stringActiver} le seuil minimum d'opération du dossier ?`,
            `Confirmez-vous l${stringActivation.toLowerCase()} du seuil minimum d'opération pour le dossier <strong>${simulation.numeroDossier}</strong> ?`,
            `${stringActivation} du seuil minimum d'opération du dossier <strong>${simulation.numeroDossier}</strong>...`,
            { color: 'green', width: 500, zIndex: 200 },
            ((resolve: ((value?: void | PromiseLike<void>) => void)) => {
                this.loading = true;
                apiDesactivationSeuilMinimum.put(null).then((_) => {
                    // Rechargement des simulations quand la suppression est terminée.
                    this.chargerSimulationDossier();
                })
                    .finally(() => {
                        this.loading = false;
                        resolve();
                    });
            }));
    }

    /**
     * Suppression de la simulation.
     * @param simulationId Identifiant de la simulation.
     */
    public async supprimerSimulationDossier(simulation: Simulation) {
        // Attention, pour l'instant ça passe mais à partir du lot 4 il ne faut pas supprimer la simulation si il y a un dossier.
        const apiSuppression = new ApiService<Simulation>(`/simulationDossier/supprimerSimulationDossier`);

        await this.faireUneActionSurSimulation(
            'Supprimer un dossier ?',
            `Confirmez-vous la suppression du dossier<br/>${simulation.numeroDossier} ?`,
            `Suppression du dossier<br/>${simulation.numeroDossier}...`,
            { color: 'red', width: 500, zIndex: 200 },
            ((resolve: ((value?: void | PromiseLike<void>) => void)) => {
                this.loading = true;
                apiSuppression.delete(simulation.id).then((_) => {
                    // Rechargement des simulations quand la suppression est terminée.
                    this.chargerSimulationDossier();
                })
                    .finally(() => {
                        this.loading = false;
                        resolve();
                    });
            }));
    }

    /**
     * Faire une action spécifique sur la simulation, cela va dépendre de la lambda en paramètre.
     *
     * @param title
     * @param message
     * @param messageDialog
     * @param options
     * @param callback
     */
    private async faireUneActionSurSimulation(
        title: string,
        message: string,
        messageDialog: string,
        options: { color: string, width: number, zIndex: number },
        callback: ((resolve: ((value?: void | PromiseLike<void>) => void)) => void)) {
        if (await this.$refs.confirm.open(title, message, options)) {
            this.$refs.dialogLoader.start(messageDialog, null, () => {
                return new Promise((resolve) => callback(resolve));
            }, { enabled: false });
        }
    }

    /**
     * Tableau de bord referentiel.    
     */
    public getTableaudeBordReferentiels(): Promise<TableauDeBordReferentiels> {
        const tdbService = new ApiService<TableauDeBordReferentiels>(`/tableauDeBord/recupererReferentielsTableauDeBord`);
        return new Promise<TableauDeBordReferentiels>((resolve, reject) => {
            tdbService.getWhereSingle("").then((reponse) => {
                resolve(reponse.data);
            }).catch((error: any) => reject(error));
        });
    }

    /**
     *  Récupérer les accords cadres sur lesquels l'utilisateur courant est responsable.
     */
    public getAccordCadreOuUtilisateurCourantEstResponsable(): Promise<AccordCadre[]> {
        const accordCadreService = new ApiService<AccordCadre>('/accordCadre/recupererAccordCadreUtilisateurCourantResponsable');
        return new Promise<AccordCadre[]>((resolve, reject) => {
            accordCadreService.getAll().then((reponse) => {
                resolve(reponse.data.data);
            }).catch((error: any) => reject(error));
        });
    }

    public get accordCadreUtilisateurs(): Utilisateur[] {
        const currentAccordCadre = this.accordsCadres.find((ac) => ac.id === this.selectedAccordCadreId);
        const result = !!currentAccordCadre ? currentAccordCadre.accordCadreSocietes.map((acs) => acs.societe.utilisateur) : [];
        return result;
    }

    public get accordCadreSocietes(): Societe[] {
        const currentAccordCadre = this.accordsCadres.find((ac) => ac.id === this.selectedAccordCadreId);
        const result = !!currentAccordCadre ? currentAccordCadre.accordCadreSocietes
            // .filter((acs) => acs.societe.utilisateur.id === this.criteresAvances.createurId)
            .map((acs) => acs.societe) : [];
        return result;
    }

    /**
     * Supprime la valeur du filtre mail utilisateur et relance la recherche.
     */
    public clearMailUtiliateur(): void {
        this.criteresAvances.mailUtilisateur = '';
        this.rechercher();
    }

    /**
     * Lance la recherche en filtrant sur les dossiers si un utilisateur a été sélectionné.
     */
    public rechercherGestionnaire(gestionnaire: Utilisateur): void {
        if (gestionnaire) {
            this.criteresAvances.parcoursUtilisateurId = SimulationOuDossier.Dossier;
            this.filtrerStatusEtRechercher(SimulationOuDossier.Dossier);
        }
        else {
            this.rechercher();
        }
    }

    public definirAccordCadre(event: AccordCadre) {
        if (event !== undefined) {
            this.accordsCadres = [event];
        }
        else {
            this.accordsCadres = [];
        }
    }

    public resetFiltreAccordCadre() {
        this.criteresAvances.membreId = 0;
        this.criteresAvances.createurId = 0;
        this.criteresAvances.accordCadreIds = [];
        this.criteresAvances.isDossiersAccordCadres = false;
        this.rechercher();
    }

    public handleConsultedDossier(idSimulationDossier: any) {
        let consultedDossier = this.simulationsDossiers.find(x => x.id === idSimulationDossier);
        this.addConsultedDossier(consultedDossier);
    }

    /**
     * Donne le focus à la combobox de recherche après un clear sur le composant.
     */
    private giveFocus(): void{
        // next tick pour laisser le temps au composant de ne plus être dans un état disable.
        this.$nextTick(() => {
            (this.$refs.searchCombobox as any).focus();
            this.cleared = false;
        });        
    }
}
