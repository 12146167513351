import { CriteriaBase } from '@/shared/models/CriteriaBase.model';
import { Resultats } from '@/shared/enums';

/**
 * Liste des filtres possible pour récupérer la liste des accords-cadres.
 */
export class AccordCadreCriteria extends CriteriaBase {
    /**
     * Le nom de l'accord-cadre.
     */
    public recherche!: string;

    /**
     * Les contrats ALL/actif/inactif.
     *
     * @type {boolean}
     * @memberof AccordCadreCriteria
     */
    public resultats: number = Resultats.ALL.toKey();

    public societeIds: number[] = [];

    public isAllRequested: boolean = true;

}
