import { SimulationOuDossier } from '@/shared/enums/SimulationOuDossier.enum';
import { ValeurReferentielle } from '@/shared/models';

/**
 * Groupes de statuts partageant le même libellé pour l'utilisateur courant.
 * */
export class GroupeStatutsSimulationDossier {

    // Identifiants des statuts.
    public ids: number[] = undefined;

    // Libelle du groupes de statuts.
    public libelle: string;

    // Type de statuts.
    public type: SimulationOuDossier;

    /**
     * Constructeur à partir d'une valeur référentielle.
     */
    constructor (statut: ValeurReferentielle) {
        this.ids = [statut.id];
        this.libelle = statut.libelle
        this.type = SimulationOuDossier.Simulation;
    }
}