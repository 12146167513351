


















































import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * Pavé pour des filtres de recherche.
 * */
@Component({
    name: 'FilterCard',
})
export default class FilterCard extends Vue {

    // Titre du pavé.
    @Prop({ default: 'Titre' })
    public title!: string;

    // Sous-titre du pavé.
    @Prop({ default: '' })
    public subTitle!: string;

    // Indique si il y a un chargement en cours.
    @Prop({ default: false })
    public loading: boolean;

    // Fonction à lancer au click sur le bouton 'Rechercher'.
    @Prop()
    public rechercher: () => void;

    // Fonction à lancer au click sur le bouton 'Réinitialiser les filtres'.
    @Prop()
    public reinitialiserFiltres: () => void;

    public show = true;
}
