import { Mixins, Prop, Component } from 'vue-property-decorator';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { DataTableHeader, PagingAndSorting } from '@/shared/models';
import { DateHelper, BooleanHelper } from '@/shared/helpers';
import { SimulationDossierCriteria } from './models/SimulationDossierCriteria.model';
import { Profils } from '@/shared/enums';
import { UserProfile } from '@/store/modules/auth/types';
import { SimulationOuDossier } from '@/shared/enums/SimulationOuDossier.enum';
import { GroupeStatutsSimulationDossier } from './models/GroupeStatutsSimulationDossier';
import { StatutDossierAsString } from '@/shared/enums/StatutDossier.enum';
import template from './SimulationTabUserInterne.vue';
import '@/shared/extension/ArrayExtensions';
import { TableauDeBordUserInterne } from '../../models/TableauDeBordUser.model';
import { OrigineDossier } from '../../shared/enums/Profils.enum';

@Component({
    ...template,
    name: 'SimulationTabUserInterne'
})
export default class SimulationTabUserInterne extends Mixins(GrilleMixin) {
    // Elements de la grille.
    //@Prop({ default: () => new Array<Simulation>() })
    //public items!: SimulationDossier[];

    // Méthode permettant de charger les simulations/dossier.
    @Prop({ default: new SimulationDossierCriteria() })
    public chargerSimulationDossier: (simulationDossierCriterias: SimulationDossierCriteria) => void;

    // Elements de la grille.
    @Prop({ default: () => new Array<TableauDeBordUserInterne>() })
    public items!: TableauDeBordUserInterne[];

    @Prop({ default: () => new Array<TableauDeBordUserInterne>() })
    public recentlyConsulted!: TableauDeBordUserInterne[];

    // Indique si l'utilisateur est un interne.
    @Prop({ default: false })
    public isUserAdmin!: boolean;

    @Prop({ default: 0 })
    public totalItems!: number;

    // Détermine si il faut afficher le bouton de duplication.
    @Prop({ default: false })
    public showActionDuplication!: boolean;

    // Détermine si il faut afficher le bouton de désactivation du seuil minimum d'opération.
    @Prop({ default: false })
    public showActionInverserActivationSeuilMinimumOperation!: boolean;

    // Chargement des éléments de la grille en cours ou non.
    @Prop({ default: false })
    public loading: boolean;

    // Critères de recherche avec la pagination.
    @Prop({ default: new SimulationDossierCriteria() })
    public simulationDossierCriterias: SimulationDossierCriteria;

    @Prop({ default: () => new Array<GroupeStatutsSimulationDossier>() })
    public statuts: Array<GroupeStatutsSimulationDossier>;

    /**
     * Le profil de l'utilisateur.
     */
    @Prop()
    public userProfile: UserProfile;

    private iconesOrigineDossier: { [id: string]: string } = {
        [OrigineDossier.Entreprise]: 'fa fa-chalkboard-teacher',
        [OrigineDossier.SyndicSDC]: 'fa fa-building',
        [OrigineDossier.Installateur]: 'fa fa-hard-hat',
        [OrigineDossier.ApporteurAffairesSDC]: 'fas fa-handshake',
        [OrigineDossier.ApporteurAffairesNonSDC]: 'fas fa-handshake',
        [OrigineDossier.ApporteurAffairesAvecClientInstallateur]: 'fas fa-handshake',
    };

    /**
     * Indique si l'utilisateur est un apporteur d'affaires.
     */
    get isApporteurAffaire(): boolean {
        return this.userProfile &&
            this.userProfile.profilCode === Profils.ApporteurAffaires;
    }

    get getSimulationDossier(): Array<TableauDeBordUserInterne> {
        if (this.simulationDossierCriterias.isFiltreSelectionne) {
            return this.items;
        }
        else {
            return [...this.recentlyConsulted, ...this.items];
        }
    }

    /**
     * Indique si l'utilisateur peut supprimer le dossier.
     */
    public canDeleteSimulationDossier(simulationDossier: TableauDeBordUserInterne): boolean {
        // #80007 : Utilisateur externes -> Une fois le dossier envoyé : griser la fonction supprimer
        const statutsBloquesGestionnaireBo = [
            StatutDossierAsString.ENVOYE_ENGIE,
            StatutDossierAsString.EN_TRAITEMENT_ENGIE,
            StatutDossierAsString.FINALISE_ENGIE,
            StatutDossierAsString.DEPOSE_PNCEE,
            StatutDossierAsString.TRAITE_PNCEE,
            StatutDossierAsString.NON_DEPOSE_PNCEE,
            StatutDossierAsString.PAYE_ENGIE,
        ];

        return this.isUserAdmin
            || (
                simulationDossier.utilisateurCreationId === parseInt(this.userProfile.sub, 10)
                && !statutsBloquesGestionnaireBo.find(x => x === simulationDossier.statut)
            );
    }

    public redirectionRouter(simulationOuDossier: SimulationOuDossier): string {     
        return simulationOuDossier === SimulationOuDossier.Simulation ?
            'editer-simulation' : 'editer-dossier';
    }

    // Colonnes du tableau
    get colonnesInterne(): DataTableHeader[] {
        const colonnes: DataTableHeader[] = [
            { text: 'Statut ', id: 'statut' },
            { text: 'N° dossier', id: 'numeroDossier' },
            { text: 'Nom du dossier', id: 'nomDuDossier' },
            { text: 'Opérations', id: 'operations' },
            { text: 'Origine du \ndossier', id: 'origineDossier' },
            { text: 'Raison sociale \nbénéficiaire', id: 'raisonSocialeBeneficiare' },
            { text: 'Raison sociale \ninstallateur', id: 'raisonSocialeInstallateur' },
            { text: 'Raison sociale \napporteur d´affaires', id: 'raisonSocialeApporteurAffaire' },
            { text: 'Nom du site \ndes travaux', id: 'nomDuSiteTravaux' },
            { text: 'Volume CEE\n(MWhc)', id: 'VolumeCumas' },
            { text: 'Montant total\nCEE (€)', id: 'montantTotal' },
            { text: 'Date d´achèvement \ndes travaux', id: 'dateAchevement' },
            { text: 'Nom du gestionnaire \nENGIE', id: 'nomDuGestionnaire' },
            { text: '', align: 'center', id:'actions', class:'actionsClass'},
        ];

        return colonnes;
    }

    // Override du getData de GrilleMixin.
    public getData() {
        this.simulationDossierCriterias.pagingAndSorting = PagingAndSorting.buildFromPaginationObject(
            this.pagination
        );
        this.chargerSimulationDossier(this.simulationDossierCriterias);
    }

    /**
    * Renvoie la somme total du volume CEE des operations d'une simulation/dossier
    * @param simulationDossier
    * @returns Somme totale volume CeePrecarite + CeeClassique
    */
    public getSommeVolumesCee(simulationDossier: TableauDeBordUserInterne): number {
        return simulationDossier.volumeCeeClassique + simulationDossier.volumeCeePrecarite;
    }

    /**
     * Formate une date pour qu'elle soit au format FR.
     * @param date La date.
     */
    public formatDate(date: string): string {
        return DateHelper.format(date);
    }

    /**
     * Récupération du libellé pour un booléen.
     * @param value Le booléen.
     * @returns Le libellé.
     */
    public getLibelleOuiNon(value: boolean): string {
        return BooleanHelper.toStringOuiNon(value);
    }

    /**
     * Renvoie l’icône de l'origine du dossier selon le profil utilisateur du créateur
     * @param simulationDossier
     * @returns L'origine du dossier.
     */
    public getIconeOrigineDossier(origineDossier: string): string {
        return this.iconesOrigineDossier[origineDossier];
    }

    /**
     * Indique pour une chaîne données si elle existe dans le type enum OrigineDossier
     * @param origineDossier
     */
    public enumOrigineDossierContains(origineDossier: string): boolean {
        return Object.values(OrigineDossier).some((v) => v === origineDossier);
    }

    /**
    * Renvoie le montant total d'un dossier ou simulation (Classique + Precarité)
    * @param Dossier
    */
    public getMontantTotal(simulationUserInterne: TableauDeBordUserInterne): number {
        return simulationUserInterne.montantTotalClassique + simulationUserInterne.montantTotalPrecarite;
    }

    public handleOuvertureSimulationDossier(simulationDossierId: number, simulationouDossier: any): void {
        this.$emit('ouverture-dossier', simulationDossierId);
        (this.$router as any).push({ name: this.redirectionRouter(simulationouDossier), params: { id: simulationDossierId } });
    }
}
