import { render, staticRenderFns } from "./TableauDeBord.vue?vue&type=template&id=6c11bcd4&scoped=true&"
import script from "./TableauDeBord.ts?vue&type=script&lang=ts&"
export * from "./TableauDeBord.ts?vue&type=script&lang=ts&"
import style0 from "./TableauDeBord.scss?vue&type=style&index=0&id=6c11bcd4&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c11bcd4",
  null
  
)

export default component.exports